.allocated-unit-info{
    position: relative;
    text-align: center;
    float: left;
    /*
    margin-right: 0.5vw;
    width: 4.9vw;
    */
    margin: 0.5vh;
    padding: 1vw;
    font-size: 2.3vh;
    border: 0.1vh white solid;
    border-radius: 1vh;
    box-sizing: border-box;
}

.allocated-unit-name-small{
    font-size: 0.8vw;
    line-height: 0.8vw;
}

.allocated-unit-name-big{
    font-size: 1.1vw;
    line-height: 1.1vw;
}

.profile-img{
    width: 100%;
    text-align: center;
    float: left;
    box-sizing: border-box;
    box-shadow: 0 0.5vh 1vh 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*min styling øverst*/


.AllocatedUnit{
    position: relative;
    float: left;
    width: 95%;
    height: 100%;
    overflow: visible;
    padding: 2%;
    box-sizing: border-box;
    text-align: initial;
}

.AllocatedUnit .padding-content{
    position: relative;
    width: 100%;
    height: 100%;
}

.AllocatedUnit h2{
    text-align: left;
    padding-left: 1.5%;
}


.AllocatedUnit .units-container{
    float: left;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    box-sizing: border-box;
    border-radius: 5px;
}

.AllocatedUnit .units{
    max-height: 80.5vh;
    overflow: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1.5% 0.8%;
}

.AllocatedUnit .unit{
    position: relative;
    text-align: center;
    float: left;
    margin-right: 0.5vw;
    padding-bottom: 0.6vw;
}

.AllocatedUnit .unit img{
    width: 2.5vw;
    border-radius: 1vh;
    box-sizing: border-box;
}

.AllocatedUnit .units .unit.booked img{
    border: 0.3vw red solid;
}


.AllocatedUnit .unit p{
    padding: 0;
    margin: 0.3vw 0;
}

.AllocatedUnit .unit .unit-description{
    max-width: 5vw;
}

.AllocatedUnit .unit .unit-description .unit-name{
    font-size: 0.8vw;
    line-height: 0.9vw;
}

.AllocatedUnit .error{

    font-size: 1vw;
}








/*HERFRA */
.AllocatedUnit .button{
    background: rgb(180,180,180);
    border: 1px solid black;
    padding: 10px 20px;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.AllocatedUnit .free-unit{
    float: left;
    padding: 20px;
    border: 1px solid black;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.AllocatedUnit .free-unit p, .Resource .free-unit .button{
    float: left;
}
